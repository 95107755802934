body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg1 {
  background-image: url("/assets/img/banner/11.jpg");
}
.bg2 {
  background-image: url("/assets/img/category/1.jpg");
}
.bg3 {
  background-image: url("/assets/img/category/2.jpg");
}
.bg4 {
  background-image: url("/assets/img/category/3.jpg");
}
.bg5 {
  background-image: url("/assets/img/category/4.jpg");
}
.bg6 {
  background-image: url("/assets/img/category/5.jpg");
}
.bg7 {
  background-image: url("/assets/img/category/6.jpg");
}
.bg8 {
  background-image: url("/assets/img/banner/1.jpg");
}
.bg9 {
  background-image: url("/assets/img/event/1.jpg");
}
.bg10 {
  background-image: url("/assets/img/event/2.jpg");
}
.bg-light {
  background-color: #ffb606 !important;
}
.navbar a {
  color: #fff;
}
.profile .nav-tabs a {
  width: 33.3%;
}
.login {
  background-color: #f5f5f5;
}
.login .logo {
  background-color: #ffb606;
  padding: 15px;
  border-radius: 5px;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
table a {
  color: #2d9aeb;
}
.setbgcolor {
  background-color: #ffb606;
}

.err_below {
  color: red;
  font-size: 11px;
}
